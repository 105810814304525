/*eslint-disable */

import { VueEditor } from "vue2-editor";
import { bus } from '@/main';
import countries from '@/services/country.json';
import toast from '@/services/toast';

export default {
  name: 'ngo-form',
  components: {
    VueEditor
  },
  props: [],
  data () {
    return {
      allCountriesList: [],
      associatedWithNgo:'',
      countryOfNgo:'',
      countryList:[],
      ngoWebsiteLink:'',
      ngoDocument:null,
      ngoFileName:'',
      uploadImageOfDocumentArr:[],
      ngoTitle:'',
      ngo_description:'',
      submitted:false,
      typeOfNgo:'',
      cityOfNgo:'',
      ngoBanner:null,
      ngoBannerName:'',
      ngoLogo:null,
      ngoLogoName:'',
      editorOptions: {
        placeholder: '',
        editorToolbar: ['bold','italic','link']
      },
      editorData: '',
      bio:''
    }
  },
  computed: {

  },
  mounted () {
    Object.keys(countries).map((key, index) => {
      this.countryList.push({
        name: countries[key],
        value: key
      })
    });
    this.countryList.sort((a, b) => (a.name > b.name) ? 1 : -1)
  },
  methods: {
    handleNgoLogoAttachmentUpload(event) {
      if(event.target.files && event.target.files.length > 0) {
        this.ngoLogo = event.target.files[0];
        this.ngoLogoName = this.ngoLogo.name
      }else {
        this.ngoLogoName = '';
      }
    },
    handleNgoBannerAttachmentUpload(event) {
      if(event.target.files && event.target.files.length > 0) {
        this.ngoBanner = event.target.files[0];
        this.ngoBannerName = this.ngoBanner.name
      }else {
        this.ngoBannerName = '';
      }
    },
    handleAttachmentUpload(event) {
      if(event.target.files && event.target.files.length > 0) {
        this.ngoDocument = event.target.files[0];
        this.ngoFileName = this.ngoDocument.name
      }else {
        this.ngoFileName = '';
      }
    },
    updateNgoInfoBeforeSubmitMethod(){
      this.$validator.validateAll().then((result) => {
        if(result){
          this.submitted =  true
          var formData = new FormData();
          formData.append('name', this.ngoTitle);
          formData.append('description', this.ngo_description);
          formData.append('country', this.countryOfNgo);
          formData.append('purpose', this.associatedWithNgo);
          formData.append('address', this.ngoWebsiteLink);
          formData.append('flag_type_of_form', 'NGO');
          formData.append('city', this.cityOfNgo);
          formData.append('type', this.typeOfNgo);
          if(this.ngoDocument){
            formData.append('proof', this.ngoDocument);
            formData.append('filename', this.ngoFileName);
          }
          if(this.ngoBanner){
            formData.append('banner', this.ngoBanner);
          }
          if(this.ngoLogoName){
            formData.append('logo', this.ngoLogo);
          }
          if(this.bio){
            formData.append('bio', this.bio);
          }
          this.$store.dispatch('registerNgoInfoFun', formData)
            .then((res) => {  
              let notificationPayload = { 
                type:'ngo_created',
                data_id:res.data.data.id,
                message:"NGO has been created"
              }
              this.$store.dispatch('postNotificationFunc', notificationPayload)       
              toast.success(res.data.msg);
              this.submitted =  false
              this.$store.dispatch('getNGOApiFun')
              this.closeUpdateAboutUserInfoPopup()
            }).catch((err) => {
              toast.error(err.data.data.user_msg || err.data.user_msg || err.data.msg);
              this.submitted =  false
            })
          return;
        }
      })
    },
    closeUpdateAboutUserInfoPopup(){
      document.body.classList.remove('popup_open_body')
      bus.$emit('ngoSignUpFormPopupBus', false);
    },
  }
}