/*eslint-disable */
import {
  mapGetters
} from 'vuex';
const $ = window.jQuery = require('jquery');
import countries from '@/services/country.json'
import {bus} from '@/main';  
import ngoSignUpForm from '@/components/private/Ngo-Info-Signup/';
import blurbContentNgo from '@/components/private/blurb-content-for-ngo/';
export default {
  name: 'my-ngo-support-group-list',
  components: {
    'ngo-signup-popup':ngoSignUpForm,
    'blurb-content-ngo' : blurbContentNgo
  },
  props: [],
  data () {
    return {
      ngoData:null,
      ngoDataResults:[],
      nextPageUrl:'',
      search_term:'',
      userLoading:false,
      sliderTouchDrag:false,
      countryList:[],
      disabledFilters:'',
      filterLoading:false,
      ngoSupportGroupFlag:'ngo',
      supportGroupData:null,
      supportGroupDataResults:[],
      ngoData:null,
      ngoDataResults:[],
      ngoDataNextPageUrl:'',
      supportGroupNextPageUrl:'',
      joinRequestPopupState:false,
      joinId:'',
      ngoSignUpFormPopup:false,
      ngoBlurbContentPopup:false,
    }
  },
  computed: {
    ...mapGetters([
      'ngoListGetters'
    ]),
    getAllDoctorsListFun(){
     this.ngoData = this.$store.getters.ngoListGetters
     return;
    },
    SetIntialStatus() {
      if (this.ngoData) {
        this.nextPageUrl = this.ngoData.next
        this.ngoDataResults = this.ngoData.data
      }
      return;
    },
  },
  mounted () {
    Object.keys(countries).map((key, index) => {
      this.countryList.push({
        name: countries[key].toLowerCase(),
        value: key.toLowerCase()
      })
    });
    this.countryList.sort((a, b) => (a.name > b.name) ? 1 : -1)
    this.getAllDoctorsListFun
    this.SetIntialStatus
    $(document).ready(function(){
      $(".member_search_filter_label .form-control").click(function(){
        $('.member_search_filter_label').addClass("show_search")
      })
      $(document).click(function(event) {
        if (!$(event.target).closest(".member_search_filter_label").length) {
          this.isOpen = false;
          $('.member_search_filter_label').removeClass("show_search")
        }
      });
    })
    bus.$on('ngoSignUpFormPopupBus', (data) => {
      this.ngoSignUpFormPopup = data
    });
    bus.$on('ngoBlurbContentPopupBus', (data) => {
      this.ngoBlurbContentPopup = data
    });
  },
  methods: {
    ngoSignUpFormPopupMethod(){
      document.body.classList.add('popup_open_body')
      this.ngoBlurbContentPopup =  true
    },
    ngoSupportGroupFunc(){
      if(this.ngoSupportGroupFlag == 'Support Group'){
        this.filterLoading = true
         this.$store.dispatch('getSupportGroupListFunc').then((response) => {
           this.supportGroupData = response.data
           this.supportGroupDataResults = this.supportGroupData.data
           this.supportGroupNextPageUrl = response.data.data.data.next
           this.filterLoading = false
        }).catch((err) => {
           this.filterLoading = false
        })
      }else{
        this.filterLoading = true
         this.$store.dispatch('getNGOApiFun').then((response) => {
          this.ngoData = response.data
          this.ngoDataResults = this.ngoData.data
          this.ngoDataNextPageUrl = response.data.data.data.next
          this.filterLoading = false
        }).catch((err) => {
           this.filterLoading = false
        })
      }
    },
    getCountryFlag: function(countryCode, type) {
      if (countryCode == null) {
        countryCode = ''
      }
      let countryName = countryCode.toLowerCase()
      let country = this.countryList.filter(item => (item.value) == (countryName) || (item.name) == (countryName));
      if (country && country.length > 0) {
        country = country[0]
        if (type == 'flag') {
          country = `https://567c9b4f06e54c9c9229fe11191a6432.s3.ca-central-1.amazonaws.com/media/shiny/${country.value}.png`
        } else {
          country = country.name
        }
      } else {
        country = ''
      }
      return country;
    },
  },
  watch:{
    getAllDoctorsListFun(){
      return this.$store.getters.ngoListGetters
    },
    SetIntialStatus() {
      if (this.ngoData) {
        this.nextPageUrl = this.ngoData.next
        this.ngoDataResults = this.ngoData.data
      }
      return;
    },
  }
}