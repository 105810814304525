/*eslint-disable */
import Multiselect from 'vue-multiselect'
import ngoForm from './ngo-form';
import supportGroupForm from './support-group-form';
import { bus } from '@/main';
export default {
  name: 'ngo-info-signup',
  components: {
    Multiselect,
    'ngo-form' : ngoForm,
    'support-group-form': supportGroupForm
  },
  props: [
    'updateAboutPopupKeySTATE'
  ],
  data () {
    return {
      open:false,
      flagTypeOfForm:'NGO',
    }
  },
  computed: {
  },
  mounted () {
  },
  methods: {
    clickedOutside() {
      this.open = false;
    },
    toggleDropdown() {
      this.open = !this.open;
    },
    flagTypeOfFormChange(){
      this.$validator.reset();
    },
    closeUpdateAboutUserInfoPopup(){
      document.body.classList.remove('popup_open_body')
      bus.$emit('ngoSignUpFormPopupBus', false);
    },
  },
  watch: {
  },
}