import { render, staticRenderFns } from "./ngo-form.html?vue&type=template&id=1cc11512&scoped=true&external"
import script from "./ngo-form.js?vue&type=script&lang=js&external"
export * from "./ngo-form.js?vue&type=script&lang=js&external"
import style0 from "./ngo-form.scss?vue&type=style&index=0&id=1cc11512&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1cc11512",
  null
  
)

export default component.exports