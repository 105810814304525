import { render, staticRenderFns } from "./support-group-form.html?vue&type=template&id=461dbf70&scoped=true&external"
import script from "./support-group-form.js?vue&type=script&lang=js&external"
export * from "./support-group-form.js?vue&type=script&lang=js&external"
import style0 from "./support-group-form.scss?vue&type=style&index=0&id=461dbf70&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "461dbf70",
  null
  
)

export default component.exports